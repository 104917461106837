<template>

<LineasModal
  :show="modalLineas"
  :lineas="itemsSinReferencia"
  :importe="precio"
  :esVenta="esVenta"
  @cancelled="modalLineas=$event"
  @deleteCuota="eliminarDirecta()"
  @guardar="guardarLineas"
  ref="lineasModal"
/>

<CCard :class="'border-0 shadow-none mt-3'">
  <CCardHeader class="bg-white">
    <div class="d-flex align-items-center justify-content-between">
      <span class="title-card-list">
        <CIcon name="cil-money" class="me-2" />
        {{ title }}
      </span>

    <div class="mt-1 header-button-container">
      <CButton :disabled="loading" class="send-button" size="sm" color="primary" @click="lineasModal()">
          {{ 'Modificar'}}
      </CButton>
    </div>
    </div>
  </CCardHeader>
  <spinner-ttt :show="loading"></spinner-ttt>
  <CCardBody>
    <CTable
      v-if="loaded"
      :items="itemsMapeados"
      :columns="campos"
      :tableProps="{
            hover: true,
            striped: true,
            responsive: configResponsive
          }"
      @row-click="lineasModal"

    >

    <template #opciones="{item}">
      <td class="px-0">
        <div class="dropdown">
          <CIcon class="dropdown-toggle" size="lg" name="cil-options" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li @click="eliminar(item)"><span class="dropdown-item"><CIcon size="lg" name="cil-trash" class="me-2 text-danger"/>Eliminar </span></li>
          </ul>
        </div>

      </td>
    </template>
    </CTable>
    <h3 class="text-center mt-4 mb-5" v-if="items.length < 1">
      Sin resultados
    </h3>
    <!--<div v-if="!esVenta" d-flex flex-row class="acciones-disponibles ms-3">
          <div class="col-12 col-md-auto">
            <CDropdown>
              <CDropdownToggle size="sm" color="primary">Acciones Globales</CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem @click="modalDelete()"><CIcon name="cil-trash" class="me-2 text-danger"/> Eliminar</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </div>
    </div>-->
  </CCardBody>
</CCard>

<toast ref="toast"></toast>
</template>

<script>
import { formatNum, formatDateCustom } from '@/app/shared/utils/tools';
import { CUOTA_FIELDS, CONFIG_PANEL, LINEA_FIELDS } from '@/config';

export default {
  name: 'LineasList',
  props: {
    elemento: { type: String, default: 'Lineas' },
    title: { type: String, default: 'Lineas' },
    loading: Boolean,
    loaded: Boolean,
    lineas: { type: Array, default() {return[]}, required: true },
    producto: {type:Object, required: false},
    esVenta: {type:Boolean, default: false},

    rows: { type: Number, default: 0 },
    currentPage:  { type: Number, default: 1 },
    perPage:  { type: Number, default: 25 },
    total_paginas:  { type: Number, default: 1 },
  },
  data() {
    return {
      formatDateCustom,
      campos: [
        // { key: "id", label: "Id", sorter: true ,  _style: 'width:5%' },
        {key: 'concepto', label: 'Concepto'},
        {key: 'cantidad', label: 'Cantidad'},
        {key: 'base_imponible', label: 'Base Imponible'},
        {key: 'importe', label: 'Importe'},
        {key: 'importe_iva', label: 'Importe IVA'},
        {key: 'iva', label: 'IVA'},
        {key: 'opciones', label: '', sorter: false}
      ],
      element: 'Linea',
      itemsSeleccionados: [],
      itemsSinReferencia: [],

      modalLineas: false
    }
  },
  computed: {
    items:{
      get: function () {
        return this.lineas;
      },
      set: function (newValue) {
        this.$emit("update:lineas", newValue);
      },
    },
    currentPageSon: {
      get: function() {
        return this.currentPage;
      },
      set: function(newValue) {
        this.$emit('update:currentPage', newValue)
      }
    },
    loadingList: {
      get: function() {
        return this.loading;
      },
      set: function(newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    configResponsive() {
      return CONFIG_PANEL.responsive;
    },
    precio() {
      if(!this.items || this.items.length < 1) return 0;

      const precio = this.items.reduce((initialValue, currentValue) => {

        return Number(initialValue.importe) + Number(currentValue.importe)
      })

      return precio;
    },

    itemsMapeados() {
      let items = [];

      this.items.forEach(i => {
        items.push({...i});
      })


      return items.map(item => {
          item.iva = item.iva ? `${formatNum(item.iva)} %` : '-'
          item.base_imponible = item.base_imponible ? `${formatNum(item.base_imponible)} €` : '-'
          item.importe = item.importe ? `${formatNum(item.importe)} €` : '-'
          item.importe_iva = item.importe_iva ? `${formatNum(item.importe_iva)} €` : '-'

          return item;
        });
    },
  },
  methods: {
    getRowCount: function() {
      return this.rows;
    },

    lineasModal(item = null, index, column, e) {
      //Se obtienen las cuotas de manera que no se obtengan por referencia para que al salir del modal se reseteen
      if(!item || ((item && !['indice', undefined].includes(column)) || esVenta)) {
        this.itemsSinReferencia = [];

        this.items.forEach(item => {
          this.itemsSinReferencia.push({...item});
        });
        if (!item && this.itemsSinReferencia.length < 1) {
          this.itemsSinReferencia.push({...LINEA_FIELDS, indice: `#${this.itemsSinReferencia.length + 1}` });
        }

        this.modalLineas = true;
      }
    },

    formatNum(n) {
      return formatNum(n);
    },

    guardarLineas(items) {
      this.cerrarModal();
      console.log(Array.from(items));
      this.$emit('guardarLineas', items);
    },

    getDescuento(item) {
      const descuento = (item.descuento_importe * 100) / item.importe;

      return `${descuento.toFixed(2)}%`
    },

    checkearSeleccionados() {
      this.items.map(item => item._selected = false);

      this.itemsSeleccionados.forEach(item => {
        this.items[this.items.indexOf(this.items.filter(i => i.id == item.id)[0])]._selected = item._selected;
      });

      this.items = [...this.items];

      return this.items.filter(item => item._selected === true);
    },

    modalDelete(){
      const checked = this.checkearSeleccionados();

      if(checked.length){
        this.$emit('delete', true)
      } else {
        this.$refs.toast.showToast(`No se ha seleccionado un admin para eliminar.`, null, 'warn', '5000');
      }
    },

    eliminar(item) {
      this.$emit('deleteCuota', item);
    },

    eliminarDirecta(item) {
      this.$emit('deleteCuotaDirecta', item);
    },

    cerrarModal() {
      this.$refs.lineasModal.cerrarModal()
    }
  }
}
</script>

<style>

</style>
