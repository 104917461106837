<template>
  <CBadge :class="classBadge" :color="getColorStatus(attribute)" > {{ getLabelStatus(attribute) }} </CBadge>
<!-- <strong :style="getColorStyleStatus(attribute)" :class="classBadge"> {{ getLabelStatus(attribute) }} </strong> -->

</template>


<script>

export default {
  name: 'BadgeConvocatoriaStatus',
  props: {
    attribute: String,
    label: {type: String,default: ''},
    classBadge: String
  },
  methods: {

   /*
    Pendiente
    Confirmada
    Pendiente documentación o pago
    Matriculado
    Finalizada
    Baja
   */

    getColorStatus( status ) {
      return status === 'Confirmada asistencia' ? 'info'
          : status === 'Realizada' ? 'success'
             : status === 'Rechazada asistencia' ?  'danger' : 'secondary'
    },

    getLabelStatus( status ) {
      return status
    },

    getColorStyleStatus( status ) {
      return status === 'Confirmada asistencia' ? 'color: #0d6efd'
          : status === 'Realizada' ?  'color: #2eb85c'
             : status === 'Rechazada asistencia' ?  'color: e55353' : 'color: ced2d8'
    },


     /*

      Secondary Color
    HEX:	#ced2d8
    RGB:	rgb(206, 210, 216)

        Warning Color
    HEX:	#f9b115
    RGB:	rgb(249, 177, 21)

      Danger Color
    HEX:	#e55353
    RGB:	rgb(229, 83, 83)

      Success Color
    HEX:	#2eb85c
    RGB:	rgb(46, 184, 92)

      Dark Color
    HEX:	#636f83
    RGB:	rgb(99, 111, 131)

      Primary Color
    HEX:	#321fdb
    RGB:	rgb(50, 31, 219)


   */

  }

}
</script>
