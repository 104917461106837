<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>Video de {{ alumno }}</CModalTitle>
      </CModalHeader>

      <CModalBody class="text-center d-block p-0 m-0">
        <spinner-ttt :show="loading"/>
        <ShowVideoZiggeo class="p-0 m-0" v-if="video" :video_token="video" @cargado="loading = false"/>
      </CModalBody>
    </CModal>
  </div>
</template>

<script>

export default {
  name: 'VerVideoModal',
  props: {
    alumno: { type: String, required: true },
    video: {type: String, required: true},
    show: { type: Boolean, default: false, required: true },
  },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
}
</script>
