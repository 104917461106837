
import { httpClient, setResponseType } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.centro;

export const downloadExcel = (filename, model = null ) => {
  setResponseType('blob');
  return httpClient.post(`${model}/to-excel`, {name: filename}).then(res => {
    setResponseType('json');
    return res
  });
};

export const resetResponseType = () => {
  setResponseType('json');
};

export const suggestGlobal = (model, term = "") => {
  let filter = `nombre=${term}`;
  return httpClient.get(`${URL}/${model}/suggest?${filter}`).then(res => res.data);
};
