<template>
  <CBadge :class="classBadge" :color="getColorStatus(attribute)" > {{ getLabelStatus(attribute) }} </CBadge>
<!-- <strong :style="getColorStyleStatus(attribute)" :class="classBadge"> {{ getLabelStatus(attribute) }} </strong> -->

</template>

<script>
export default {
  name: 'BadgeFacturas',
  props: {
    attribute: String,
    label: {type: String,default: ''},
    classBadge: String
  },
  methods: {

   /*
    Abierto //
    En curso  //
    Cerrado
    Finalizada //

    Un informe puede estar en estado (Tipología Estados Informes)
    - Abierto (por defecto)
    - En curso (Cuando se ha realizado alguna memoria, condiciona reabrir beca)
    - Cerrado (Cuando están realizados los 2 o 3 memorias)
   */

    getColorStatus( status ) {
      // return status === 'Anulada' ?  'secondary'
      return status === 'Pagada' ? 'success'
             : status === 'Pendiente' ?  'danger' : 'secondary'
    },

    getLabelStatus( status ) {
      return status
    },

    getColorStyleStatus( status ) {
      // return status === 'Anulada' ? 'color: #ced2d8'
      return status === 'Pagada' ?  'color: #2eb85c'
             : status ===  'Pendiente'?  'color: e55353' : 'color: ced2d8'
    },

  }

}
</script>
