<template>

<DeleteModal
      :show="modalDeleteItem"
      :title="'Convocatoria'"
      :element="'las convocatoria seleccionada'"
      @cancelled="modalDeleteItem=$event"
      @deleteItems="desasociarConvocatoria"
      />

<ComentariosConvocatoriaModal
      :show="modalComentarios"
      :convocatoria="convocatoriaToEdit"
      @cancelled="modalComentarios=$event"
      @modificarComentarios="modificarComentarios"
    />

  <CRow>
    <spinner-ttt :show="loadingList"></spinner-ttt>
    <CSmartTable
      v-if="loaded"
      v-model:items="items"
      :columns="camposConvocatorias"
      class="mt-3"
      striped
      responsive
      hover
      :tableProps="{
        hover: true,
        striped: true,
        responsive: configResponsive,
      }"
      columnSorter
      clickable-rows
      :no-items-label="'Sin resultados'"
    >
    <template #fecha="{item}">
      <td>
        {{ formatDateTimeCustom(item.fecha) }}
      </td>
    </template>

    <template #iconos="{item}">
      <td>
        <div class="d-flex justify-content-between">
        <CIcon @click="toggleConvocatoriaCampo(item, 'confirmada_asistencia')" v-if="item.confirmada_asistencia" name="cil-people" size="lg" class="text-info me-2" title="Asistencia confirmada"/>
        <CIcon @click="toggleConvocatoriaCampo(item, 'confirmada_asistencia')" v-else name="cil-people" class="text-secondary me-2" size="lg" title="Asistencia no confirmada"/>

        <CIcon @click="toggleConvocatoriaCampo(item, 'rechazada_asistencia')" v-if="item.rechazada_asistencia" name="cil-X" size="lg" class="text-danger me-2" title="Asistencia rechazada"/>
        <CIcon @click="toggleConvocatoriaCampo(item, 'rechazada_asistencia')" v-else name="cil-X" class="text-secondary me-2" size="lg" title="Asistencia no rechazada"/>

        <CIcon @click="toggleConvocatoriaCampo(item, 'realizada')" v-if="item.realizada" name="cil-check" size="lg" class="text-success me-2" title="Entrevista realizada"/>
        <CIcon @click="toggleConvocatoriaCampo(item, 'realizada')" v-else name="cil-check" class="text-secondary me-2" size="lg" title="Entrevista no realizada"/>

        <CIcon @click="comentariosModal(item)" name="cil-comment-square" class="text-black me-3" size="lg" title="Gestiona los comentarios"/>

        <!--<CIcon @click="guardarConvocatoria(item)" v-if="item.changed" name="cil-check-alt" class="text-success me-4" size="xl" title="Guardar"/>-->
        </div>
      </td>
    </template>

    <template #opciones="{ item }">
      <td class="px-0">
        <div class="dropdown">
          <CIcon
            class="dropdown-toggle"
            size="lg"
            name="cil-options"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

            <!-- <li @click="modalEditar(item)">
              <span class="dropdown-item">
                <CIcon name="cil-pencil" class="me-2" /> Editar</span>
            </li>        -->

            <li @click="desasociar(item)">
              <span class="dropdown-item">
                <CIcon name="cil-minus" class="me-2 text-danger" />
                Desasociar convocatoria
                </span>
            </li>
            <li @click="recordarEntrevista(item)">
              <span class="dropdown-item">
                <CIcon name="cil-bell" class="me-2 text-warning" />
                Recordar convocatoria
                </span>
            </li>

          </ul>
        </div>
      </td>
    </template>
    </CSmartTable>

    <h5 class="text-center mt-4 mb-5" v-if="items.length < 1 || !loaded">
      Sin resultados
    </h5>
  </CRow>
</template>

<script>
import { formatDateTimeCustom } from '@/app/shared/utils/tools';
import { CONFIG_PANEL } from '@/config';
import ComentariosConvocatoriaModal from '@/app/shared/components/modals/ComentariosConvocatoriaModal'

export default {
  name: 'ConvocatoriasMatriculaList',
  components: {
    ComentariosConvocatoriaModal
  },
  props: {
    loading: Boolean,
    loaded: Boolean,
    convocatorias: { type: Array, default() {return[]}, required: true },
  },
  data() {
    return {
      formatDateTimeCustom,
      moment,
      camposConvocatorias: [
        // { key: 'check', label: '', sorter: false},
        { key: 'fecha', label: 'Fecha', sorter: true, _style: { width: '20%'}}, // { minWidth: '130px' },
        { key: 'sede', label: 'Sede', sorter: true},
        { key: 'modalidad', label: 'Modalidad', sorter: true}, //
        { key: 'tipo', label: 'Tipo', sorter: true },
        { key: 'responsable', label: 'Responsable', sorter: false },
        { key: 'iconos', label: '', sorter: false},
        { key: 'opciones', label: '', sorter: false}
      ],
      element: 'Convocatoria',
      allChecked: false,
      itemsSeleccionados: [],
      modalDeleteItem: false,
      modalComentarios: false,
      convocatoriaToEdit: null,
      deleteItem: null,

      puedeCrear: false
    }
  },
  computed: {
    items:{
      get: function () {
        return this.convocatorias;
      },
      set: function (newValue) {
        this.$emit("update:convocatorias", newValue);
      },
    },
    currentPageSon: {
      get: function() {
        return this.currentPage;
      },
      set: function(newValue) {
        this.$emit('update:currentPage', newValue)
      }
    },
    loadingList: {
      get: function() {
        return this.loading;
      },
      set: function(newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    configResponsive() {
      return CONFIG_PANEL.responsive;
    }
  },
  methods: {
    getRowCount: function() {
      return this.rows;
    },

    selectAllItems(){
      if (this.items.every( item => item._selected === true)){
        this.$emit('updateChecked', false);
        this.allChecked = false;
      } else {
        this.$emit('updateChecked', true);
        this.allChecked = true;
      }
    },

    goToDetail(item, index, column, e){
      if (![ 'nombre', undefined].includes(column)) {
        this.$router.push({ path: "/gestion/matriculas/convocatorias/" + item.id });
      }
    },

    check (item) {
      const data = this.items.filter( user => user.id == item.id)[0];

      const val = Boolean(this.items[this.items.indexOf(data)]._selected);

      this.items[this.items.indexOf(data)]._selected = !val;
      this.items[this.items.indexOf(data)].checked = !val;

      this.items = [...this.items];
    },

    toggleConvocatoriaCampo(convocatoria, campo) {
      if(campo == 'confirmada_asistencia' && convocatoria[campo] == false) convocatoria.rechazada_asistencia = false;
      if(campo == 'rechazada_asistencia' && convocatoria[campo] == false) convocatoria.confirmada_asistencia = false;

      convocatoria[campo] = !convocatoria[campo];
      this.$emit('guardarConvocatoria', convocatoria);

      //convocatoria.changed = true;
    },

    comentariosModal(convocatoria) {
      this.convocatoriaToEdit = convocatoria;

      this.modalComentarios = true;
    },

    modificarComentarios(convocatoria) {
      this.modalComentarios = false;
      this.$emit('modificarComentarios', convocatoria)
    },

    cambiarPagina(e) {
      this.$emit('cambiarPagina', e)
    },

    guardarConvocatoria(convocatoria) {
      convocatoria.changed = false;

      this.$emit('guardarConvocatoria', convocatoria);
    },

    recordarEntrevista(convocatoria) {
      this.$emit('recordarEntrevista', convocatoria.convocatoria_id);
    },

    desasociar(item) {
      this.deleteItem = item;
      this.modalDeleteItem = true;
    },

    desasociarConvocatoria() {
      this.modalDeleteItem = false;

      this.$emit('desasociarConvocatoria', this.deleteItem);
      this.deleteItem = null;
    },
  }
}
</script>

<style>

</style>
