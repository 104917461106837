import { h, resolveComponent } from 'vue'

const PrincipalSaldo = () => import('@/app/views/publica/views/Principal');
const PagoOK = () => import('@/app/views/publica/views/PagoOK');
const PagoKO = () => import('@/app/views/publica/views/PagoKO');

const publicaRoutes = [
  {
    path: "/publica",
    name: "Principal",
    component: PrincipalSaldo,
    meta: {
      auth: false
    }
  },

  // respuesta redsys
  {
    path: "/success",
    name: "Pago realizado",
    component: PagoOK,
    meta: {
      auth: false
    }
  },
  {
    path: "/error",
    name: "Pago",
    component: PagoKO,
    meta: {
      auth: false
    }
  },
];

export default publicaRoutes;
