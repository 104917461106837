<template>

  <!--       :width="320"
      :height="180" -->

  <ziggeo-player
      :apiKey="apiKey"
      :video="videoToken"
      :responsive="true"
      :theme="'modern'"
      :themeColor="'red'"
      :aspectratio="'640,480'"
      width="100%"
      :allowpip="true"
      :skipinitial="true"
      @playerReady="playerReady"
      @attached="playerAttached"
      @playing="playerPlaying"
  ></ziggeo-player>

</template>

<script>
// import { ref, reactive, onMounted } from 'vue';

import { ZIGGEO_TOKEN } from '@/config';

export default {
  name: 'ShowVideoZiggeo',
  props: {
    video_token: { type:String, required: true},
  },
  setup(props, context) {

    let apiKey = ZIGGEO_TOKEN;
    let playerInstance = undefined;
    let videoToken = props.video_token;

    // const videoToken = reactive({
    //   guid: props.video_token
    // })

    const playerReady = (instance) => {
      playerInstance = instance;
    };

    // starting vue-ziggeo@2.0.0 version embedding argument also accessible
    const playerAttached = (embedding, data) => {
      // console.log('player attached', data)
      // Updated recorder instance
      playerInstance = embedding;
      context.emit('cargado');
    };

    const playerPlaying = (embedding) => {
      // console.log('player playing');
    };

    return {
      apiKey,
      videoToken,
      playerReady,
      playerPlaying,
      playerAttached
    };
  }
}
</script>
