<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>Cambiar de grupo</CModalTitle>
      </CModalHeader>

      <CModalBody class="d-block">
        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CRow>
            <CCol>
              <CFormLabel class="required">Grupo</CFormLabel>
              <Field
                as="CFormSelect"
                class="mb-3"
                id="grupos" name="grupos"
                :disabled="!grupos"
                :rules="'required'"
                v-model="nuevoGrupo_id"
                v-on:keyup.enter="filtrarItems"
                :class="{ 'is-invalid': formError(errors, 'grupos')}"
                v-bind:invalid-feedback="errors.grupos"
                >
              <option value="">Selecciona un grupo</option>
                <option v-for="(item) in grupos" :key="item.id" :value="item.value">
                    {{ item.label }}
                </option>
              </Field>
              <small class="text-danger ml-2" v-if="formError(errors, 'grupos')">{{ errors.grupos }}</small>
            </CCol>
          </CRow>
        </Form>
      </CModalBody>

       <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
        <CButton class="text-light" @click="cambiarGrupo" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Cambiar
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import { fetchGrupos } from '../../global/services/grupo-service';
import { formError } from '../../utils/tools';

export default {
  name: 'CambiarGrupoModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    grupoActual: {type: Object},
    element: { type: String, default: 'Elemento', required: true},
    master_id: { type: String }
  },
  data() {
    return {
      nuevoGrupo_id: null,
      grupos: [],
    }
  },
  mounted() {
    this.getGrupos();
  },
  computed: {
    showModal() {
      return this.show;
    },
  },

  methods: {
    cambiarGrupo() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.$emit('cambiarGrupo', this.nuevoGrupo_id);
        this.nuevoGrupo_id = null;
      });
    },

    getGrupos() {
      fetchGrupos(this.master_id).then(res => {
        if(this.grupoActual) res.data = res.data.filter(i => i.id != this.grupoActual.id);

        res.data.forEach(grupo => {
            this.grupos.push({
              label: grupo.nombre,
              value: grupo.id
            })
        });
      }).catch(e => {
        console.log(e);
        this.$refs.toast.showToast("Error obteniendo los grupos", null, "error", "5000");
      })
    },

    cerrarModal() {
      this.nuevoGrupo_id = null;
      this.$emit('cancelled',false);
    },

    formError(errors, value) {
      return formError(errors, value);
    },
  }
}

</script>
