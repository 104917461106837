<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="() => $emit('cancelled',false)">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>Modificar comentarios de la convocatoria</CModalTitle>
      </CModalHeader>

      <CModalBody>
        <CRow>
          <CCol sm="12">
            <CFormLabel>Comentario candidato</CFormLabel>
            <CFormTextarea
              rows="7"
              disabled
              placeholder="No hay ningún comentario"
              v-model="convocatoria.comentario_candidato"
              >
            </CFormTextarea>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="mt-3" sm="12">
            <CFormLabel>Comentario interno</CFormLabel>
            <CFormTextarea
              rows="7"
              placeholder="Introducte un comentario"
              v-model="convocatoria.comentario_interno"
              >
            </CFormTextarea>
          </CCol>
        </CRow>
      </CModalBody>

       <CModalFooter>
        <CButton @click="$emit('cancelled',false)" color="link" class="text-secondary">Cancelar</CButton>
        <CButton class="text-light" @click="$emit('modificarComentarios', convocatoria)" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Guardar
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
</template>

<script>

export default {
  name: 'ComentariosConvocatoriaModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    convocatoria: { type: Object, required: true}
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
}
</script>
