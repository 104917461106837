import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.venta;

export const fetchPlantilla = (matricula_id) => {
  return httpClient.get(`${URL}/plantilla/${matricula_id}`).then(res => res.data);
}

export const createVenta = (matricula_id = null, producto_id = null, contacto_id, fecha, cuotas = [], titulo = '') => {
  return httpClient.post(`${URL}`, {matricula_id, producto_id, contacto_id, fecha, cuotas, titulo}).then(res => res.data);
}

export const fetchOneVenta = (ventaId) => {
  return httpClient.get(`${URL}/${ventaId}`).then(res => res.data);
}

export const updateVenta = (venta_id, fecha, cuotas) => {
  return httpClient.put(`${URL}/${venta_id}`, {cuotas, fecha}).then(res => res.data);
}

export const deleteVenta = (venta_id) => {
  return httpClient.delete(`${URL}/${venta_id}`).then(res => res.data);
}
