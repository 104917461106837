<template>
  <div class="animated fadeIn">
    <DeleteModal
      :show="modalDelete"
      :title="'Cuota'"
      :element="'la cuota seleccionada'"
      @cancelled="modalDelete=$event"
      @deleteItems="deleteCuota"
    />
    <CModal :visible="showModal && !modalDelete" size="xl" @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{ esVenta ? 'Cuotas de la venta' : 'Nueva / Editar Cuota' }}</CModalTitle>
      </CModalHeader>

      <spinner-ttt :show="loading"></spinner-ttt>
      <CModalBody>
        <CRow>
          <CCol sm="auto" class="mb-3">
            <CFormLabel>Importe</CFormLabel>
            <CFormInput
              class="importe"
              type="text"
              id="importe" name="importe"
              v-bind:value="cambiarImporte()"
              disabled
              />
          </CCol>
        </CRow>
        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CRow v-for="item in items" :key="item.indice" class="d-flex align-items-center">
            <div style="width:4%" class="pt-2 my-3">
              {{ item.indice }}
            </div>
            <div :style="esVenta ? 'width:13%' : 'width:15%'" class="mb-3">
              <CFormLabel>Concepto</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                :rules="`required`"
                :disabled="item.has_movimientos"
                :id="`concepto-${item.indice}`" :name="`concepto-${item.indice}`"
                v-model="item.concepto"
                placeholder="Introduce un concepto"
                :class="{ 'is-invalid': formError(errors, `concepto-${item.indice}`)}"
                v-bind:invalid-feedback="errors[`concepto-${item.indice}`]"
              />
            </div>
            <div :style="esVenta ? 'width:14%' : 'width:15%'" class="mb-3">
              <CFormLabel>Tipo</CFormLabel>
              <Field
                as="CFormSelect"
                type="text"
                :disabled="item.has_movimientos"
                :id="`tipo-${item.indice}`" :name="`tipo-${item.indice}`"
                v-model="item.tipo_id"
                :rules="`required`"
                placeholder="Introduce un tipo"
                :class="{ 'is-invalid': formError(errors, `tipo-${item.indice}`)}"
                v-bind:invalid-feedback="errors[`tipo-${item.indice}`]"
              >
              <option value="">Seleccionar</option>
              <option v-for="(item) in tiposFiltrados(item.tipo_id)" :key="item.id" :value="item.value">
                  {{ item.label }}
              </option>
              </Field>
            </div>
            <div :style="esVenta ? 'width:12%' : 'width:15%'" class="mb-3">
              <CFormLabel>Importe</CFormLabel>
              <Field
                as="CFormInput"
                type="number"
                :rules="`required`"
                :disabled="item.has_movimientos"
                :id="`importe-${item.indice}`" :name="`importe-${item.indice}`"
                v-model="item.importe"
                placeholder="Introduce un importe"
                :class="{ 'is-invalid': formError(errors, `importe-${item.indice}`),
                'importe': true}"
                v-bind:invalid-feedback="errors[`importe-${item.indice}`]"
              />
            </div>
            <div :style="esVenta ? 'width:13%' : 'width:15%'" class="mb-3">
              <CFormLabel>Descuento</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                :disabled="item.has_movimientos"
                :id="`descuento-${item.indice}`" :name="`descuento-${item.indice}`"
                v-model="item.descuento"
                placeholder="Introduce concepto de descuento"
                :class="{ 'is-invalid': formError(errors, `descuento-${item.indice}`)}"
                v-bind:invalid-feedback="errors[`descuento-${item.indice}`]"
              />
            </div>
            <div :style="esVenta ? 'width:13%' : 'width:15%'" class="mb-3">
              <CFormLabel>Desc. Importe</CFormLabel>
              <Field
                as="CFormInput"
                type="number"
                :disabled="item.has_movimientos"
                :id="`descuento-importe-${item.indice}`" :name="`descuento-importe-${item.indice}`"
                v-model="item.descuento_importe"
                placeholder="Introduce un descuento"
                :class="{ 'is-invalid': formError(errors, `descuento-importe-${item.indice}`),
                'importe': true}"
                v-bind:invalid-feedback="errors[`descuento-importe-${item.indice}`]"
              />
            </div>
            <div :style="esVenta ? 'width:13%' : 'width:15%'" class="mb-3">
              <CFormLabel>Importe Final</CFormLabel>
              <CFormInput
                type="text"
                :id="`importe-final-${item.indice}`" :name="`importe-final-${item.indice}`"
                class="importe"
                :value="getImporteFinal(item)"
                disabled
              />
            </div>
            <div v-if="esVenta" :style="'width:15%'" class="mb-3">
              <CFormLabel>Vencimiento</CFormLabel>
              <Field
                as="CDatePicker"
                :disabled="item.has_movimientos"
                :id="`fecha-vencimiento-${item.indice}`" :name="`fecha-vencimiento-${item.indice}`"
                v-model="item.fecha_vencimiento"
                v-model:date="item.fecha_vencimiento"
                @date-change="fecha => setDate(item, 'fecha_vencimiento', fecha)"
                placeholder="Introduce una fecha"
                :invalid="formError(errors, `fecha-vencimiento-${item.indice}`)"
                v-bind:invalid-feedback="errors[`fecha-vencimiento-${item.indice}`]"
              />
            </div>
            <div style="width:3%" class="pt-2 my-3 pe-2">
              <CIcon v-if="!item.has_movimientos" class="clickeable" @click="borrar(item)" icon="cil-trash" />
            </div>
          </CRow>
        </Form>
        <h3 class="text-center mt-4 mb-5" v-if="!items || items.length < 1">
          Sin Cuotas
        </h3>
        <div class="p-2 importe">
          <a v-on:click.prevent @click="nuevaCuota" href="#">+ Nueva cuota</a>
        </div>
      </CModalBody>

       <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
        <!--<CButton v-if="administrador.id" class="text-light" @click="guardarAdmin" color="primary">
          Guardar
        </CButton>-->
        <CButton class="text-light" @click="guardar" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Guardar
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import { CUOTA_FIELDS } from '@/config';
import { formError, formatNum, setStoreData, blobToJson } from '@/app/shared/utils/tools';
import { fetchTypologyWithSons } from '@/app/shared/global/services/tipologia-service';
import { fetchPlantilla } from '@/app/shared/global/services/venta-service';

export default {
  name: 'CuotasModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    cuotas: {type: Array, required: true},
    elemento: {type: String, default: 'Cuota'},
    importe: Number,
    esVenta: {type:Boolean, default: false},
    matriculaId: String
  },
  mounted() {
      const res = setStoreData('tipos-de-cuotas');
      if (res !== null) {
        this.tipos = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id}));
        return
      }


      fetchTypologyWithSons('tipos-de-cuotas').then(async res => {
        const respuesta = res instanceof Blob ? await blobToJson(res) : res;


        this.tipos = respuesta.data.hijos.map((item) => ({ label: item.nombre, value: item.id}));
      }).catch(e => {
        console.log(e);
        this.$refs.toast.showToast( `Error obteniendo los tipos`, null, "error", "5000");
      })
  },
  computed: {
    showModal() {
      return this.show;
    },
    items: {
      get: function () {
        return this.cuotas
      },
      set: function (newValue) {
        this.$emit("update:cuotas", newValue);
      }
    },
    precio: {
      get: function () {
        return this.importe
      },
      set: function (newValue) {
        this.$emit("update:importe", newValue);
      }
    },
  },
  data() {
    return {
      loading: false,
      tipos: [],
      selectedName: '',
      cuotasModificadas: [],

      itemDelete: null,
      modalDelete: false,
    }
  },
  methods: {
    nuevaCuota() {
      this.items.push({...CUOTA_FIELDS, indice: `#${this.items.length + 1}` });
      this.items = [...this.items];
    },

    guardar() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.items.map(i => {
          i.importe_final = Number(i.importe - i.descuento_importe).toFixed(2);
          i.importe_cancelado = i.importe_cancelado ?? 0;

          return i;
        });
        this.$emit('guardar', this.items);
      })
    },

    borrar(item) {
      this.itemDelete = item;
      this.modalDelete = true;
    },

    deleteCuota() {
      this.modalDelete = false;

      this.items.splice(this.items.indexOf(this.itemDelete), 1);

      if(this.itemDelete.id) this.$emit('deleteCuota', this.itemDelete);

      this.items.map((item, index) => item.indice = `#${index + 1}`);
    },

    cambiarImporte() {
      let precio = 0;

      this.cuotas.forEach(cuota => {
        precio += Number(cuota.importe);
        precio -= Number(cuota.descuento_importe);
      })

      return `${formatNum(precio.toFixed(2))} €`;
    },

    getImporteFinal(item) {
      return `${formatNum((item.importe - item.descuento_importe).toFixed(2))} €`
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    cerrarModal() {
      this.loading = false;
      this.$emit('cancelled',false);
    },

    selectItem(item) {
      this.administrador = {...item};
      this.selectedName = this.administrador.name;
    },

    tiposFiltrados(v) {
      return this.tipos.filter(tipo => {
        if(tipo.value == 70 || tipo.value == v) return true;

        return !this.items.some(item => item.tipo_id == tipo.value);
      });
    },

    setDate(item, clave, fecha) {
      item[clave] = fecha ? moment(fecha).format('YYYY-MM-DD') : null;
    },
  }
}
</script>

<style>

</style>
