import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.contacto;

export const fetchAllContactos = (page = null, filtro = '', perPage = null) => {
  let filter = filtro.length > 0 ? filtro : "";
  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&per_page=${perPage}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};

export const createContacto = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
}

export const fetchOneContacto = (contactoId) => {
  return httpClient.get(`${URL}/${contactoId}`).then(res => res.data);
};

export const updateContacto = (contactoToEdit) => {
  return httpClient.put(`${URL}/${contactoToEdit.id}`, contactoToEdit).then(res => res.data);
};

export const deleteContacto = (contactoId) => {
  return httpClient.delete(`${URL}/${contactoId}`).then(res => res);
};

export const actualizarAlumno = (contactoId, alumnoToEdit) => {
  return httpClient.put(`${URL}/actualizar-alumno/${contactoId}`, alumnoToEdit).then(res => res);
}

export const convertirAlumno = (contactoId) => {
  return httpClient.put(`${URL}/convertir-alumno/${contactoId}`).then(res => res.data);
}

export const crearDireccionContacto = (contactoId, direccion) => {
  return httpClient.post(`${URL}/crear-direccion/${contactoId}`, direccion).then(res => res.data);
}

export const direccionesContacto = (contactoId) => {
  return httpClient.get(`${URL}/direcciones/${contactoId}`).then(res => res.data);
}
