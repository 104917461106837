<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{!item.id ? 'Nuevo movimiento' : 'Editar movimiento'}} :: {{ matricula }}</CModalTitle>
      </CModalHeader>

      <spinner-ttt :show="loading"></spinner-ttt>
      <CModalBody>
        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Concepto</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                id="concepto" name="concepto"
                :rules="'required'"
                placeholder="Introduce un concepto"
                v-model="item.concepto"
                :class="{ 'is-invalid': formError(errors, 'concepto')}"
                v-bind:invalid-feedback="errors.concepto"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'concepto')">{{ errors.concepto }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Importe</CFormLabel>
              <Field
                as="CFormInput"
                id="importe" name="importe"
                type="number"
                :disabled="item.id"
                placeholder="Introduce un importe"
                :rules="'required'"
                v-model="item.importe"
                :class="{ 'is-invalid': formError(errors, 'importe')}"
                v-bind:invalid-feedback="errors.importe"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'importe')">{{ errors.importe }}</small>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Fecha</CFormLabel>
              <Field
                as="CDatePicker"
                id="fecha" name="fecha"
                rules="required"
                placeholder="Introduce una fecha"
                v-if="!loading"
                v-model="item.fecha"
                v-model:date="item.fecha"
                @date-change="fecha => setDate('fecha', fecha)"
                :invalid="formError(errors, 'fecha')"
                v-bind:invalid-feedback="errors.fecha"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'fecha')">{{ errors.fecha }}</small>
            </CCol>

            <CCol sm="6">
              <CFormLabel class="required">Cuota</CFormLabel>
              <Field
                as="CMultiSelect"
                id="cuota" name="cuota"
                :rules="'required'"
                :options="listadoCuotas"
                select-all-label="Seleccionar todos"
                :disabled="item.id"
                v-model="item.cuotas"
                :invalid="formError(errors, 'cuota')"
                :class="{ 'is-invalid': formError(errors, 'cuota')}"
                v-bind:invalid-feedback="errors.cuota"
              >
              </Field>
              <small class="text-danger ml-2" v-if="formError(errors, 'cuota')">{{ errors.cuota }}</small>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Entidad</CFormLabel>
              <Field
                as="CFormSelect"
                id="entidad" name="entidad"
                :rules="'required'"
                :disabled="!entidades || entidades.length < 1"
                v-model="item.entidad_id"
                :class="{ 'is-invalid': formError(errors, 'entidad')}"
                v-bind:invalid-feedback="errors.entidad"
              >
              <!--  :options="tipos" -->
                <option :value="''">Seleccionar</option>
                <option v-for="(item) in entidades" :key="item.id" :value="item.value">
                    {{ item.label }}
                </option>
              </Field>
              <small class="text-danger ml-2" v-if="formError(errors, 'entidad')">{{ errors.entidad }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Forma de pago</CFormLabel>
              <Field
                as="CFormSelect"
                id="entidad" name="forma de pago"
                :rules="'required'"
                :disabled="!formas_pago || formas_pago.length < 1"
                v-model="item.forma_pago_id"
                :class="{ 'is-invalid': formError(errors, 'forma de pago')}"
                v-bind:invalid-feedback="errors['forma de pago']"
              >
              <!--  :options="tipos" -->
                <option :value="''">Seleccionar</option>
                <option v-for="(item) in formas_pago" :key="item.id" :value="item.value">
                    {{ item.label }}
                </option>
              </Field>
              <small class="text-danger ml-2" v-if="formError(errors, 'forma de pago')">{{ errors['forma de pago'] }}</small>
            </CCol>
          </CRow>

        </Form>
      </CModalBody>

       <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
        <CButton v-if="item.id" class="text-light" @click="guardarMovimiento" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Guardar
        </CButton>
        <CButton v-else class="text-light" @click="crearMovimiento" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Crear
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import { fetchTypologyWithSons } from '@/app/shared/global/services/tipologia-service';

import { MOVIMIENTO_FIELDS } from '@/config';
import { formatNum, formError, setStoreData } from '@/app/shared/utils/tools';

export default {
  name: 'MovimientoModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    movimiento: {type: Object},
    matricula: String,
    cuotas: {type: Array, default: []}
  },
  computed: {
    showModal() {
      return this.show;
    },
    listadoCuotas() {
      if(!this.cuotas) return [];

      let IDS = [];
      if(this.movimiento && this.movimiento.cuotas) IDS = this.movimiento.cuotas.map(c => c.value);

      return this.cuotas.filter(c => this.movimiento.id || c.importe_pendiente != 0).map(c => {
        c = {
          value: c.id,
          text: `${c.concepto} (${formatNum(c.importe_pendiente)} €)` ,
          selected: IDS.includes(c.id)
        };

        return c;
      });
    },
    item: {
      get: function () {
        return this.movimiento
      },
      set: function (newValue) {
        this.$emit("update:movimiento", newValue);
      }
    },
  },
  data() {
    return {
      listadoTipos: [],
      loading: false,

      entidades: [],
      formas_pago: [],
    }
  },
  mounted() {
    this.getEntidades();
    this.getFormasPago();
  },
  methods: {
    crearMovimiento() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('crearMovimiento');
      })
    },

    getEntidades() {
      const res = setStoreData('entidades');
      if (res !== null) {
          this.entidades = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id }));
          return
      }

      fetchTypologyWithSons('entidades').then(res => {
          if (res.status != "success") return;
          this.entidades = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id }));
      }).catch(e => {
          console.log(e);
          this.$refs.toast.showToast(`Error obteniendo las entidades`, null, "error", "5000");
      })
    },

    getFormasPago() {
      const res = setStoreData('formas-de-pago');
      if (res !== null) {
          this.formas_pago = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id }));
          return
      }

      fetchTypologyWithSons('formas-de-pago').then(res => {
          if (res.status != "success") return;
          this.formas_pago = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id }));
      }).catch(e => {
          console.log(e);
          this.$refs.toast.showToast(`Error obteniendo las formas de pago`, null, "error", "5000");
      })
    },

    guardarMovimiento() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('guardarMovimiento')
      })
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    cerrarModal() {
      this.loading = false;
      this.item = {...MOVIMIENTO_FIELDS};
      this.$emit('cancelled',false);
    },

    setDate(clave, fecha) {
      this.item[clave] = fecha ? moment(fecha).format('YYYY-MM-DD') : null;
    },
  }
}
</script>

<style>

</style>
