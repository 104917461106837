<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{`Nuevo ${elemento}`}} :: {{ relacion }}</CModalTitle>
      </CModalHeader>

      <spinner-ttt :show="loading"></spinner-ttt>
      <CModalBody>
        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CRow>
            <CCol sm="6" class="mt-3">
              <CFormLabel class="required">Responsable <CIcon icon="cil-playlist-add" class="text-primary"/></CFormLabel>
              <div class="d-flex align-items-center">
                <Field
                  as="SimpleTypeahead"
                  type="text"
                  id="responsable" name="responsable"
                  :items="data"
                  :minInputLength="1"
                  :itemProjection="item => item.name"
                  v-model="searchQuery"
                  placeholder="Introduce un nombre"
                  :class="{ 'is-invalid': formError(errors, 'responsable_id'),
                            'form-control': true}"
                  v-bind:invalid-feedback="errors.responsable_id"
                  @selectItem="item => selectItem(item)"
                  @onInput="onInput"
                  @onBlur="item => onBlur(item)"
                />
                <div v-if="isLoading" class="ms-3">
                  <CSpinner size="sm" class="" color="secondary" />
                </div>
              </div>
              <small class="text-danger ml-2" v-if="formError(errors, 'responsable_id')">{{ errors.responsable_id }}</small>

              <Field
                as="CFormInput"
                type="text"
                id="responsable_id" name="responsable_id"
                :rules="'tieneId'"
                v-model="selectedItem"
                v-bind:invalid-feedback="errors.admin"
                class="d-none"
                />
            </CCol>
          </CRow>
        </Form>
      </CModalBody>

       <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
        <!--<CButton v-if="administrador.id" class="text-light" @click="guardarAdmin" color="primary">
          Guardar
        </CButton>-->
        <CButton class="text-light" @click="crearAdmin" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Crear
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import { USUARIO_FIELDS } from '@/config';
import { formError } from '@/app/shared/utils/tools';
import { useAutocomplete } from '@/use/useAutocomplete/useAutocomplete';
import { ref } from 'vue';

export default {
  name: 'AdminModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    admin: {type: Object},
    elemento: {type: String, default: 'administrador'},
    modeloTipo: { type: String, required: true },
    modeloId: { type: Number, required: true },
    relacion: String
  },
  emits: ['delete', 'crearAdmin', 'cancelled', 'guardarAdmin'],
  setup(props, context) {

    const { fetchSuggestions, data, isLoading, hasFailed } = useAutocomplete(
      // 'https://api.talento-ephos.trestristestigres.com/api/',
      {
        // queryParam: 'nombre',
        transformData: (data) => data,
        debounceDuration: 500,
      },
    )

    const searchQuery = ref('')
    const selectedItem = ref(null)
    const selectedName = ref('')

    function onInput() {
      if (searchQuery.value?.length > 0) {
        fetchSuggestions(props.modeloTipo, props.modeloId, searchQuery.value, (props.elemento == 'responsable' && props.modeloTipo == 'sedes') ? 'persona' : props.elemento)
      } else {
        data.value = []
        selectedItem.value = null
      }
    }

    function selectItem(item) {
      selectedItem.value = {...item};
      searchQuery.value = item.name;
      selectedName.value = item.name;
    }

    function onBlur(item) {
      if (item.input !== selectedName.value) {
        selectedItem.value = null;
        selectedName.value = '';
      }
    }



    return {
      // onItemClick,
      onInput,
      isLoading,
      hasFailed,
      searchQuery,
      data,
      selectItem,
      selectedItem,
      selectedName,
      onBlur,
    }
  },
  computed: {
    showModal() {
      return this.show;
    },
    administrador: {
      get: function () {
        return this.admin
      },
      set: function (newValue) {
        this.$emit("update:admin", newValue);
      }
    },
  },
  data() {
    return {
      loading: false,
      listadoAdmins: [],
      selectedName: '',
    }
  },
  methods: {
    crearAdmin() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.administrador = this.selectedItem;
        this.$emit('crearAdmin', this.selectedItem);
      })
    },

    guardarAdmin() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('guardarAdmin')
      })
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    cerrarModal() {
      this.loading = false;
      this.selectedItem = null;
      this.searchQuery = '';
      this.$emit('cancelled',false);
    },
  }
}
</script>

<style>

</style>
