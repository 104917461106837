<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{!item.id ? 'Nueva factura' : 'Editar factura'}} :: {{ matricula }}</CModalTitle>
      </CModalHeader>

      <spinner-ttt :show="loading"></spinner-ttt>
      <CModalBody>
        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel>Orden de compra</CFormLabel>
              <CFormInput
                id="codigo" name="codigo"
                type="text"
                placeholder="Introduce un codigo"
                v-model="item.codigo"
              />
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Fecha</CFormLabel>
              <Field
                as="CDatePicker"
                id="fecha" name="fecha"
                rules="required"
                timepicker
                placeholder="Introduce una fecha"
                v-if="!loading"
                v-model="item.fecha"
                v-model:date="item.fecha"
                @date-change="fecha => setDate('fecha', fecha)"
                :invalid="formError(errors, 'fecha')"
                v-bind:invalid-feedback="errors.fecha"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'fecha')">{{ errors.fecha }}</small>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">
              <CFormLabel class="required">Estado</CFormLabel>
              <Field
                as="CFormSelect"
                id="estado" name="estado"
                :rules="'required'"
                :disabled="!estados || estados.length < 1"
                v-model="item.estado_id"
                :class="{ 'is-invalid': formError(errors, 'estado')}"
                v-bind:invalid-feedback="errors.estado"
              >
              <option value="">Selecciona una estado</option>
                <option v-for="(item) in estados" :key="item.id" :value="item.value">
                    {{ item.label }}
                </option>
              </Field>
              <small class="text-danger ml-2" v-if="formError(errors, 'estado')">{{ errors.estado }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
                <CFormLabel class="required">Tipo</CFormLabel>
                <Field
                  as="CFormSelect"
                  id="tipo" name="tipo"
                  type="text"
                  v-model="item.tipo"
                  rules="required"
                  :invalid="formError(errors, 'tipo')"
                  v-bind:invalid-feedback="errors.tipo"
                >
                <option value="">Selecciona un tipo</option>
                <option v-for="(item) in tipos" :key="item.id" :value="item.value">
                    {{ item.label }}
                </option>
                </Field>
                <small class="text-danger ml-2" v-if="formError(errors, 'tipo')">{{ errors.tipo }}</small>
              </CCol>
          </CRow>
          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Importe</CFormLabel>
              <Field
                as="CFormInput"
                id="importe" name="importe"
                type="number"
                placeholder="Introduce un importe"
                :rules="'required'"
                v-model="item.importe"
                :class="{ 'is-invalid': formError(errors, 'importe')}"
                v-bind:invalid-feedback="errors.importe"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'importe')">{{ errors.importe }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
                <CFormLabel class="required">Direcciones</CFormLabel>
                <Field
                  as="CFormSelect"
                  id="direccion" name="direccion"
                  type="text"
                  v-model="direccion_id"
                  rules="required"
                  :invalid="formError(errors, 'direccion')"
                  v-bind:invalid-feedback="errors.direccion"

                  @change="seleccionarDireccion"
                >
                <option value="">Selecciona una dirección</option>
                <option v-for="(item) in direcciones" :key="item.id" :value="item.value">
                    {{ item.label }}
                </option>
                </Field>
                <small class="text-danger ml-2" v-if="formError(errors, 'direccion')">{{ errors.direccion }}</small>
              </CCol>
          </CRow>
          <CRow v-if="direccion_id">
          <CCol sm="6" class="mt-3">
                <CFormLabel class="required">Dirección</CFormLabel>
                <Field
                  as="CFormInput"
                  id="direccion" name="direccion"
                  type="text"
                  rules="required"
                  readonly
                  placeholder="Introduce la direccion"
                  v-model="direccion.direccion"
                  :invalid="formError(errors, 'direccion')"
                  v-bind:invalid-feedback="errors.direccion"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'direccion')">{{ errors.direccion }}</small>
              </CCol>
              <CCol sm="6" class="mt-3">
                <CFormLabel class="required">Localidad</CFormLabel>
                <Field
                  as="CFormInput"
                  id="localidad" name="localidad"
                  type="text"
                  rules="required"
                  readonly
                  placeholder="Introduce la localidad"
                  v-model="direccion.localidad"
                  :invalid="formError(errors, 'localidad')"
                  v-bind:invalid-feedback="errors.localidad"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'localidad')">{{ errors.localidad }}</small>
              </CCol>
          </CRow>
          <CRow v-if="direccion_id">
              <CCol sm="6" class="mt-3">
                <CFormLabel class="required">Provincia</CFormLabel>
                <Field
                  as="CFormInput"
                  id="provicnia" name="provicnia"
                  type="text"
                  rules="required"
                  readonly
                  placeholder="Introduce la provicnia"
                  v-model="direccion.provincia"
                  :invalid="formError(errors, 'provicnia')"
                  v-bind:invalid-feedback="errors.provicnia"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'provicnia')">{{ errors.provincia }}</small>
              </CCol>
              <CCol sm="6" class="mt-3">
                <CFormLabel class="required">País</CFormLabel>
                <Field
                  as="CFormInput"
                  id="pais" name="pais"
                  type="text"
                  rules="required"
                  readonly
                  placeholder="Introduce el país"
                  v-model="direccion.pais"
                  :invalid="formError(errors, 'pais')"
                  v-bind:invalid-feedback="errors.pais"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'pais')">{{ errors.pais }}</small>
              </CCol>
            </CRow>
            <CRow v-if="direccion_id">
              <CCol sm="6" class="mt-3">
                <CFormLabel class="required">Código postal</CFormLabel>
                <Field
                  as="CFormInput"
                  id="codigo_postal" name="codigo postal"
                  type="text"
                  rules="required"
                  readonly
                  placeholder="Introduce el codigo postal"
                  v-model="direccion.codigo_postal"
                  :invalid="formError(errors, 'codigo postal')"
                  v-bind:invalid-feedback="errors['codigo postal']"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'codigo postal')">{{ errors['codigo postal'] }}</small>
              </CCol>
              <CCol sm="6" class="mt-3">
                <CFormLabel class="required">NIF</CFormLabel>
                <Field
                  as="CFormInput"
                  id="cif" name="cif"
                  type="text"
                  rules="required"
                  readonly
                  placeholder="Introduce el NIF"
                  v-model="direccion.cif"
                  :invalid="formError(errors, 'cif')"
                  v-bind:invalid-feedback="errors.cif"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'cif')">{{ errors.cif }}</small>
              </CCol>
            </CRow>

          <CRow>
              <CCol sm="12" class="mt-3">
                <CFormLabel class="required">Descripción</CFormLabel>
                <Field
                  as="CFormTextarea"
                  id="descripcion" name="descripcion"
                  placeholder="Introduce un texto"
                  v-model="factura.descripcion"
                  rows="3"
                  rules="required"
                 :invalid="formError(errors, 'descripcion')"
                  v-bind:invalid-feedback="errors.descripcion"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'descripcion')">{{ errors.descripcion }}</small>
              </CCol>
            </CRow>

        </Form>
      </CModalBody>

       <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
        <CButton v-if="item.id" class="text-light" @click="guardarFactura" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Guardar
        </CButton>
        <CButton v-else class="text-light" @click="crearFactura" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Crear
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import { FACTURA_FIELDS, TIPOS_FACTURA } from '@/config';
import { formError, setStoreData } from '@/app/shared/utils/tools';
import { fetchTypologyWithSons } from '../../global/services/tipologia-service';
import { direccionesContacto } from '../../global/services/contacto-service';

export default {
  name: 'FacturaModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    factura: {type: Object},
    matricula: String,
    contacto_id: String
  },
  computed: {
    showModal() {
      return this.show;
    },
    item: {
      get: function () {
        return this.factura
      },
      set: function (newValue) {
        this.$emit("update:factura", newValue);
      }
    },
  },
  data() {
    return {
      listadoTipos: [],
      loading: false,
      estados: [],
      tipos: [...TIPOS_FACTURA],
      direcciones: [],
      direccion: {},
      direccion_id: '',
      nif_alumno: '',
    }
  },
  mounted() {
    this.getEstados();
    this.getDirecciones();
  },
  methods: {
    crearFactura() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.item = {
          ...this.item,
          contacto_id: this.contacto_id,
          direccion: this.direccion.direccion,
          localidad: this.direccion.localidad,
          provincia: this.direccion.provincia,
          codigo_postal: this.direccion.codigo_postal,
          pais: this.direccion.pais,
          nif: this.direccion.cif,
          base_imponible: this.item.importe,
          importe: Number(this.item.importe).toFixed(2),
          importe_iva: 0,
          iva: 0,
        }
        this.item.importe = Number(this.item.importe).toFixed(2);
        console.log("E");
        this.$emit('crearFactura');
      })
    },

    guardarFactura() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('guardarFactura')
      })
    },

    getEstados() {
      const res = setStoreData('estados-de-cuotas');
      if (res !== null) {
          this.estados = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id }));
          return
      }

      fetchTypologyWithSons('estados-de-cuotas').then(res => {
          if (res.status != "success") return;
          this.estados = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id }));
      }).catch(e => {
          console.log(e);
          this.$refs.toast.showToast(`Error obteniendo los estados`, null, "error", "5000");
      })
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    getDirecciones() {
      direccionesContacto(this.contacto_id).then(res => {
        const direccionesMapeadas = res.data.direcciones.map(direccion => {
          direccion.label = direccion.alias;
          direccion.value = direccion.id;

          return direccion;
        })


        this.nif_alumno = res.data.nif_alumno;
        this.direccion.cif = this.nif_alumno;

        this.direcciones = [...direccionesMapeadas];
      })
    },

    seleccionarDireccion() {
      if(!this.direccion_id) {
        this.direccion = {};
        return
      }

      this.direccion = {...this.direcciones.find(d => d.id == this.direccion_id), cif: this.nif_alumno}
    },

    cerrarModal() {
      this.loading = false;
      this.item = {...FACTURA_FIELDS};
      this.direccion_id = '';
      this.direccion = {};

      this.$emit('cancelled',false);
    },

    setDate(clave, fecha) {
      this.item[clave] = fecha ? moment(fecha).format('YYYY-MM-DD') : null;
    },
  }
}
</script>

<style>

</style>
