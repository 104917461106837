<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center justify-content-between">
      <slot name="header">
        <span class="d-flex align-items-center"><CIcon class="me-2" name="cil-justify-center" size="lg"/>
          Listado de Matrículas y Becas
        </span>
      </slot>
    </CCardHeader>
    <CCardBody>
      <div v-for="item in items" :key="item.id">
      <CSmartTable v-if="loaded" :items="[item]" :columns="camposMatriculas" :tableProps="{
          hover: false, responsive: configResponsive, striped: true
        }" columnSorter clickable-rows :no-items-label="'Sin resultados'"
        @row-click="goToMatriculaDetail"
          >
        <template #link="{item}">
          <td>
            <CNavLink class="clickeable" :href="`/#/gestion/matriculas/${item.id}`">
                <CIcon icon="cil-external-link" size="xl" class="clickeable" />
            </CNavLink>
          </td>
        </template>
      </CSmartTable>

      <div v-if="item.beca" class="d-flex">
      <CIcon icon="cilLevelUp" size="3xl" class="rotate-90 me-3" />
      <CSmartTable class="w-100" v-if="loaded" :items="[item.beca]" :columns="camposBecas" :tableProps="{
          hover: false, responsive: configResponsive, striped: true
        }" columnSorter clickable-rows :no-items-label="'Sin resultados'"
        @row-click="goToBecaDetail"
          >

        <template #fecha_inicio="{item}">
          <td>
            {{ formatDateCustom(item.fecha_inicio) }}
          </td>
        </template>

        <template #fecha_fin="{item}">
          <td>
            {{ formatDateCustom(item.fecha_fin) }}
          </td>
        </template>

        <template #link="{item}">
          <td>
            <CNavLink class="clickeable" :href="`/#/gestion/matriculas/becas/${item.id}`">
                <CIcon icon="cil-external-link" size="xl" class="clickeable" />
            </CNavLink>
          </td>
        </template>
      </CSmartTable>
      </div>
      </div>
      <h3 class="text-center mt-4 mb-5" v-if="items.length < 1">
          Sin matrículas
        </h3>
    </CCardBody>
  </CCard>
</template>

<script>
import { formatDateCustom } from '@/app/shared/utils/tools';
import { CONFIG_PANEL } from '@/config';

export default {
name: 'MatriculasYBecasList',
  props: {
    loading: Boolean,
    loaded: Boolean,
    matriculas: { type: Array, default() {return[]}, required: true },
  },
  created() {
    this.items = [...this.items];
  },
  data() {
    return {
      formatDateCustom,
      camposMatriculas: [
        // { key: "id", label: "Id", sorter: true ,  _style: 'width:5%' },
        {key: 'curso', label: 'Curso', sorter: false, hover: false},
        {key: 'master', label: 'Máster', sorter: false, hover: false,},
        {key: 'link', label: '', sorter: false, hover: false, _style: { width: '5%' }},
      ],
      camposBecas: [
        // { key: "id", label: "Id", sorter: true ,  _style: 'width:5%' },
        {key: 'id', label: '#', sorter: false, hover: false},
        {key: 'empresa', label: 'Empresa', sorter: false, hover: false},

        {key: 'nombre', label: 'Nombre', sorter: false, hover: false},
        {key: 'ciudad', label: 'Ciudad', sorter: false, hover: false},
        {key: 'fecha_inicio', label: 'Fecha de Inicio', sorter: false, hover: false},
        {key: 'fecha_fin', label: 'Fecha de Fin', sorter: false, hover: false},
        {key: 'link', label: '', sorter: false, hover: false, _style: { width: '5%' }},
      ],
    }
  },
  computed: {
    items:{
      get: function () {
        return this.matriculas;
      },
      set: function (newValue) {
        this.$emit("update:matriculas", newValue);
      },
    },
    loadingList: {
      get: function() {
        return this.loading;
      },
      set: function(newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    configResponsive() {
      return CONFIG_PANEL.responsive;
    }
  },
  methods: {
    goToMatriculaDetail(item, index, column, e){
      // TODO: estado_id 265. no está el campo
      // const url = item.estado == 265 ? 'matriculas' : 'inscripciones';
      const url = 'matriculas'
      this.$router.push({ path: `/gestion/${url}/${item.id}`});
    },
    goToBecaDetail(item, index, column, e){
      this.$router.push({ path: "/gestion/matriculas/becas/" + item.id });
    },
  }
}
</script>

<style>

</style>
