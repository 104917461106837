<template>
  <div class="animated fadeIn">

    <DeleteModal
            :show="modalDeleteItem"
            :title="'Factura'"
            :element="'la factura seleccionada'"
            @cancelled="modalDeleteItem=$event"
            @deleteItems="deleteFactura"
            />

     <FacturaModal
        :show="modalFactura"
        v-model:factura="factura"
        v-model:matricula="matricula.master.abreviatura"
        v-bind:cuotas="cuotas"
        :contacto_id="contacto"
        @crearFactura="nuevaFactura"
        @cancelled="modalFactura=$event"
        ref="facturasModal"

      />

    <CCard class="mt-3">
      <CCardHeader class="d-flex align-items-center justify-content-between">
        <slot name="header">
          <span class="d-flex align-items-center"><CIcon class="me-2" name="cil-justify-center" size="lg"/>
          {{ title }}</span>
        </slot>
        <div class="float-end card-header-actions">
          <CButton @click="FacturaModal()" class="button-new" size="sm" color="primary" >Nueva</CButton>
        </div>
      </CCardHeader>
      <spinner-ttt :show="loading"></spinner-ttt>

      <CCardBody>
        <CSmartTable
          v-if="loaded"
          v-model:items="items"
          :columns="campos"
          :tableProps="{
            hover: true,
            striped: true,
            responsive: configResponsive
          }"
          columnSorter
          clickable-rows
          @row-click="goToDetail"
          selectable

          :no-items-label="'Sin resultados'"
          @selected-items-change="items => itemsSeleccionados = items"
        >
        <!--           :pagination="{
            itemsPerPage: 10,
            pages: total_paginas
            }" -->

          <template #fecha="{item}">
            <td>
              {{ formatDateTimeCustom(item.fecha) }}
            </td>
          </template>

          <template #importe="{item}">
            <td>
              {{ formatNum(item.importe) }}€
            </td>
          </template>

          <template #tipo="{item}">
            <td>
              {{ capitalize(item.tipo) }}
            </td>
          </template>

          <template #estado="{item}">
            <td>
              <BadgeFacturas :attribute="item.estado" :classBadge="'status-list'" />
            </td>
          </template>

          <template #opciones="{item}">
            <td class="px-0">
              <div class="dropdown">
                <CIcon class="dropdown-toggle" size="lg" name="cil-options" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li @click="borrar(item)"><span class="dropdown-item"><CIcon name="cil-trash" class="me-2 text-danger"/> Eliminar </span></li>
                </ul>
              </div>

            </td>
          </template>

        </CSmartTable>

        <h3 class="text-center mt-4 mb-5" v-if="items.length < 1">
          Sin resultados
        </h3>

        <div d-flex flex-row class="acciones-disponibles ms-3">
              <div class="col-12 col-md-auto">
                <CDropdown>
                  <CDropdownToggle color="primary" size="sm">Acciones Globales</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem @click="modalDelete"><CIcon name="cil-trash" class="me-2 text-danger"/> Eliminar</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>
        </div>
      </CCardBody>
    </CCard>
    <toast ref="toast"></toast>
  </div>
</template>
<script>
import { formatDateTimeCustom, formatNum, capitalize } from '@/app/shared/utils/tools';
import { CONFIG_PANEL, FACTURA_FIELDS } from '@/config';

import BadgeFacturas from '@/app/shared/components/badges/BadgeFacturas';

import FacturaModal from "@/app/shared/components/modals/FacturaModal";

export default {
  name: 'FacturasMatriculaList',
  components: {
    FacturaModal,
    BadgeFacturas
  },
  props: {
    elemento: { type: String, default: 'Facturas' },
    title: { type: String, default: 'Facturas' },
    loading: Boolean,
    loaded: Boolean,
    facturas: { type: Array, default() {return[]}, required: true },
    matricula: {type: Object, required: true},
    contacto_id: String,
  },
  data() {
    return {
      formatDateTimeCustom,
      formatNum,
      capitalize,
      campos: [
        // { key: "id", label: "Id", sorter: true ,  _style: 'width:5%' },
        {key: 'numero', label: 'Número'},
        {key: 'codigo', label: 'Código'},
        {key: 'fecha', label: 'Fecha de subida'},
        {key: 'importe', label: 'Importe'},
        {key: 'tipo', label: 'Tipo'},
        {key: 'estado', label: 'Estado'},
        {key: 'opciones', label: '', sorter: false}
      ],
      allChecked: false,
      itemsSeleccionados: [],
      modalDeleteItem: false,
      modalFactura: false,
      direcciones: [],

      factura: {...FACTURA_FIELDS},
      deleteItem: null,

      puedeCrear: false
    }
  },
  computed: {
    items:{
      get: function () {
        return this.facturas;
      },
      set: function (newValue) {
        this.$emit("update:facturas", newValue);
      },
    },
    currentPageSon: {
      get: function() {
        return this.currentPage;
      },
      set: function(newValue) {
        this.$emit('update:currentPage', newValue)
      }
    },
    loadingList: {
      get: function() {
        return this.loading;
      },
      set: function(newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    configResponsive() {
      return CONFIG_PANEL.responsive;
    },
    contacto: {
      get: function () {
        return this.contacto_id;
      },
      set: function (newValue) {
        this.$emit("update:contacto_id", newValue);
      },
    }
  },
  methods: {
    getRowCount: function() {
      return this.rows;
    },

    selectAllItems(){
      if (this.items.every( item => item._selected === true)){
        this.$emit('updateChecked', false);
        this.allChecked = false;
      } else {
        this.$emit('updateChecked', true);
        this.allChecked = true;
      }
    },

    FacturaModal(item = null, index, column, e) {
      if(!item || (item && ![ 'numero', undefined].includes(column))) {
        if (item) {
          this.factura = { ...item };
        }
        this.modalFactura = true;
      }
    },

    goToDetail(item, index, column, e){
      if (!['numero', undefined].includes(column)) {
        this.$router.push({ path: "/gestion/facturas/" + item.id });
      }
    },

    check (item) {
      const data = this.items.filter( user => user.id == item.id)[0];

      const val = Boolean(this.items[this.items.indexOf(data)]._selected);

      this.items[this.items.indexOf(data)]._selected = !val;
      this.items[this.items.indexOf(data)].checked = !val;

      this.items = [...this.items];
    },

    cambiarPagina(e) {
      this.$emit('cambiarPagina', e)
    },

    checkearSeleccionados() {
      this.items.map(item => item._selected = false);

      this.itemsSeleccionados.forEach(item => {
        this.items[this.items.indexOf(this.items.filter(i => i.id == item.id)[0])]._selected = item._selected;
      });

      this.items = [...this.items];

      return this.items.filter(item => item._selected === true);
    },

    nuevaFactura() {
      console.log(this.factura);
      this.$emit('nuevaFactura', this.factura);
    },

    modificarFactura() {

      this.$emit('modificarFactura', this.factura);
    },

    modalDelete(){
      const checked = this.checkearSeleccionados();

      if(checked.length){
        this.$emit('delete', true)
      } else {
        this.$refs.toast.showToast(`No se ha seleccionado una factura para eliminar.`, null, 'warn', '5000');
      }
    },

    borrar(item) {
      this.deleteItem = item;
      this.modalDeleteItem = true;
    },

    deleteFactura() {
      this.modalDeleteItem = false;

      this.$emit('deleteFactura', this.deleteItem);
      this.deleteItem = null;
    },

    cerrarModal() {
      this.$refs.facturasModal.cerrarModal();
    }
  }
}
</script>
