<template>
  <div class="animated fadeIn">

    <DeleteModal
            :show="modalDeleteItem"
            :title="'Movimiento'"
            :element="'el movimiento seleccionado'"
            @cancelled="modalDeleteItem=$event"
            @deleteItems="deleteMovimiento"
            />

     <MovimientoModal
        :show="modalMovimiento"
        v-model:movimiento="movimiento"
        v-model:matricula="matricula.master.abreviatura"
        v-bind:cuotas="cuotas"
        @crearMovimiento="nuevoMovimiento"
        @guardarMovimiento="modificarMovimiento"
        @cancelled="modalMovimiento=$event"
        ref="movimientosModal"

      />

    <CCard class="mt-3">
      <CCardHeader class="d-flex align-items-center justify-content-between">
        <slot name="header">
          <span class="d-flex align-items-center"><CIcon class="me-2" name="cil-justify-center" size="lg"/>
          {{ title }}</span>
        </slot>
        <div class="float-end card-header-actions">
          <CButton @click="movimientoModal()" class="button-new" size="sm" color="primary" >Nuevo</CButton>
        </div>
      </CCardHeader>
      <spinner-ttt :show="loading"></spinner-ttt>

      <CCardBody>
        <CSmartTable
          v-if="loaded"
          v-model:items="items"
          :columns="campos"
          :tableProps="{
            hover: true,
            striped: true,
            responsive: configResponsive
          }"
          columnSorter
          clickable-rows
          @row-click="movimientoModal"
          selectable

          :no-items-label="'Sin resultados'"
          @selected-items-change="items => itemsSeleccionados = items"
        >
        <!--           :pagination="{
            itemsPerPage: 10,
            pages: total_paginas
            }" -->

          <template #fecha="{item}">
            <td>
              {{ formatDateCustom(item.fecha) }}
            </td>
          </template>

          <template #importe="{item}">
            <td>
              {{ formatNum(item.importe) }}€
            </td>
          </template>

          <template #cuotas="{item}">
            <td>
              <CBadge color="secondary" class="ms-2" v-for="cuota in item.cuotas" :key="cuota.value">
                {{ cuota.text ?? cuota }}
              </CBadge>

              <span v-if="item.has_incidencia" class="float-end" v-c-tooltip="{ content: item.incidencia, placement: 'top' }">
                <CIcon icon="cil-warning" class="text-warning"/>
              </span>
            </td>
          </template>

          <template #opciones="{item}">
            <td class="px-0">
              <div class="dropdown">
                <CIcon class="dropdown-toggle" size="lg" name="cil-options" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li @click="borrar(item)"><span class="dropdown-item"><CIcon name="cil-trash" class="me-2 text-danger"/> Eliminar </span></li>
                </ul>
              </div>

            </td>
          </template>

        </CSmartTable>

        <h3 class="text-center mt-4 mb-5" v-if="items.length < 1">
          Sin resultados
        </h3>

        <div d-flex flex-row class="acciones-disponibles ms-3">
              <div class="col-12 col-md-auto">
                <CDropdown>
                  <CDropdownToggle color="primary" size="sm">Acciones Globales</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem @click="modalDelete"><CIcon name="cil-trash" class="me-2 text-danger"/> Eliminar</CDropdownItem>
                  </CDropdownMenu>
                </CDropdown>
              </div>
        </div>
      </CCardBody>
    </CCard>
    <toast ref="toast"></toast>
  </div>
</template>
<script>
import { formatDateCustom, formatNum } from '@/app/shared/utils/tools';
import { CONFIG_PANEL, MOVIMIENTO_FIELDS } from '@/config';

import MovimientoModal from "@/app/shared/components/modals/MovimientoModal";

export default {
  name: 'MovimientosList',
  components: {
    MovimientoModal
  },
  props: {
    elemento: { type: String, default: 'Movimientos' },
    title: { type: String, default: 'Movimientos' },
    loading: Boolean,
    loaded: Boolean,
    movimientos: { type: Array, default() {return[]}, required: true },
    cuotas: {type: Array, default() {return[]}, required: true},
    matricula: {type: Object, required: true}
  },
  data() {
    return {
      formatDateCustom,
      formatNum,
      campos: [
        // { key: "id", label: "Id", sorter: true ,  _style: 'width:5%' },
        {key: 'fecha', label: 'Fecha'},
        {key: 'concepto', label: 'Concepto'},
        {key: 'importe', label: 'Importe'},
        {key: 'cuotas', label: 'Cuotas'},
        {key: 'opciones', label: '', sorter: false}
      ],
      element: 'Cursos',
      allChecked: false,
      itemsSeleccionados: [],
      modalDeleteItem: false,
      modalMovimiento: false,

      movimiento: {...MOVIMIENTO_FIELDS},

      puedeCrear: false
    }
  },
  computed: {
    items:{
      get: function () {
        return this.movimientos;
      },
      set: function (newValue) {
        this.$emit("update:movimientos", newValue);
      },
    },
    currentPageSon: {
      get: function() {
        return this.currentPage;
      },
      set: function(newValue) {
        this.$emit('update:currentPage', newValue)
      }
    },
    loadingList: {
      get: function() {
        return this.loading;
      },
      set: function(newValue) {
        this.$emit('update:loading', newValue)
      }
    },
    configResponsive() {
      return CONFIG_PANEL.responsive;
    }
  },
  methods: {
    getRowCount: function() {
      return this.rows;
    },

    selectAllItems(){
      if (this.items.every( item => item._selected === true)){
        this.$emit('updateChecked', false);
        this.allChecked = false;
      } else {
        this.$emit('updateChecked', true);
        this.allChecked = true;
      }
    },

    movimientoModal(item = null, index, column, e) {
      console.log("EEE")
      if(!item || (item && ![ 'fecha', undefined].includes(column))) {
        if (item) {
          this.movimiento = { ...item };
        }

        this.modalMovimiento = true;
      }
    },

    check (item) {
      const data = this.items.filter( user => user.id == item.id)[0];

      const val = Boolean(this.items[this.items.indexOf(data)]._selected);

      this.items[this.items.indexOf(data)]._selected = !val;
      this.items[this.items.indexOf(data)].checked = !val;

      this.items = [...this.items];
    },

    cambiarPagina(e) {
      this.$emit('cambiarPagina', e)
    },

    checkearSeleccionados() {
      this.items.map(item => item._selected = false);

      this.itemsSeleccionados.forEach(item => {
        this.items[this.items.indexOf(this.items.filter(i => i.id == item.id)[0])]._selected = item._selected;
      });

      this.items = [...this.items];

      return this.items.filter(item => item._selected === true);
    },

    nuevoMovimiento() {
      this.movimiento.importe = Number(this.movimiento.importe).toFixed(2);

      this.$emit('nuevoMovimiento', this.movimiento);
    },

    modificarMovimiento() {
      this.$emit('modificarMovimiento', this.movimiento);
    },

    modalDelete(){
      const checked = this.checkearSeleccionados();

      if(checked.length){
        this.$emit('delete', true)
      } else {
        this.$refs.toast.showToast(`No se ha seleccionado un movimiento para eliminar.`, null, 'warn', '5000');
      }
    },

    borrar(item) {
      this.deleteItem = item;
      this.modalDeleteItem = true;
    },

    deleteMovimiento() {
      this.modalDeleteItem = false;

      this.$emit('deleteMovimiento', this.deleteItem)
    },

    cerrarModal() {
      this.$refs.movimientosModal.cerrarModal();
    }
  }
}
</script>
