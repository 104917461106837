import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.grupo;

export const fetchGrupos = (master_id) => {
  const filter = master_id ? `master_id=${master_id}` : '';

  return httpClient.get(`${URL}?${filter}`).then(res => res.data);
}

export const getGrupo = (grupoId) => {
  return httpClient.get(`${URL}/${grupoId}`).then(res => res.data);
}

export const updateGrupo = (grupoToEdit) => {
  return httpClient.put(`${URL}/${grupoToEdit.id}`, grupoToEdit).then(res => res.data);
}

export const createGrupo = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
}

export const deleteGrupo = (grupoId) => {
  return httpClient.delete(`${URL}/${grupoId}`).then(res => res.data);
}
