<template>
  <div class="animated fadeIn">
    <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">

      <CModalHeader class="bg-primary text-light">
        <CModalTitle>{{!personaContacto.id ? 'Nueva persona de contacto' : 'Editar persona de contacto'}} :: {{ empresa }}</CModalTitle>
      </CModalHeader>

      <spinner-ttt :show="loading"></spinner-ttt>
      <CModalBody>
        <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
          <CRow>
            <CCol sm="6" class="mb-3">
                <CFormLabel>Principal</CFormLabel>
                <CFormSwitch
                  id="principal" name="principal"
                  v-model="personaContacto.principal"
                  size="xl"
                  @update:checked="checked => personaContacto.principal = checked"
                />
              </CCol>
          </CRow>
          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Nombre</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                id="name" name="nombre"
                :rules="'required'"
                placeholder="Introduce un nombre"
                v-model="personaContacto.nombre"
                :class="{ 'is-invalid': formError(errors, 'nombre')}"
                v-bind:invalid-feedback="errors.name"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'nombre')">{{ errors.nombre }}</small>
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel class="required">Email</CFormLabel>
              <Field
                as="CFormInput"
                id="email" name="email"
                type="text"
                placeholder="Introduce un email"
                :rules="'required|validEmail'"
                v-model="personaContacto.email"
                :class="{ 'is-invalid': formError(errors, 'email')}"
                v-bind:invalid-feedback="errors.email"
              />
              <small class="text-danger ml-2" v-if="formError(errors, 'email')">{{ errors.email }}</small>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel>Teléfono</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                id="telefono" name="telefono"
                placeholder="Introduce un teléfono"
                v-model="personaContacto.telefono"
              />
            </CCol>

            <CCol sm="6">
              <CFormLabel class="required">Tipo</CFormLabel>
              <Field
                as="CFormSelect"
                id="tipo" name="tipo_id"
                :options="listadoTipos"
                :rules="'required'"
                :disabled="!listadoTipos || listadoTipos.length < 1"
                v-model="personaContacto.tipo_id"
                :class="{ 'is-invalid': formError(errors, 'tipo_id')}"
                v-bind:invalid-feedback="errors.tipo"
              >
              </Field>
              <small class="text-danger ml-2" v-if="formError(errors, 'tipo_id')">{{ errors.tipo_id }}</small>
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6" class="mb-3">
              <CFormLabel>Cargo</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                id="cargo" name="cargo"
                placeholder="Introduce un cargo"
                v-model="personaContacto.cargo"
              />
            </CCol>
            <CCol sm="6" class="mb-3">
              <CFormLabel>NIF</CFormLabel>
              <Field
                as="CFormInput"
                type="text"
                id="nif" name="nif"
                placeholder="Introduce un NIF"
                v-model="personaContacto.nif"
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="12">
              <CFormTextarea
                id="observaciones" name="observaciones"
                label="Observaciones"
                placeholder="Introduce un texto"
                v-model="personaContacto.observaciones"
                rows="3"
              />
            </CCol>
          </CRow>

        </Form>
      </CModalBody>

       <CModalFooter>
        <CButton @click="cerrarModal" class="text-secondary" color="link">Cancelar</CButton>
        <CButton v-if="personaContacto.id" class="text-light" @click="guardarPersona" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Guardar
        </CButton>
        <CButton v-else class="text-light" @click="crearPersona" color="primary">
          <!-- <CIcon name="cil-check-circle" />  -->
          Crear
        </CButton>
       </CModalFooter>

    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import { fetchTypologyWithSons } from '@/app/shared/global/services/tipologia-service';

import { PERSONAS_CONTACTO_FIELDS } from '@/config';
import { formError, setStoreData } from '@/app/shared/utils/tools';

export default {
  name: 'PersonaContactoModal',
  props: {
    show: { type: Boolean, default: false, required: true },
    persona: {type: Object},
    empresa: String
  },
  mounted() {
    const res = setStoreData(this.tipologiaSlug);
    if (res !== null) {
      this.listadoTipos = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id}));
      return
    }
    fetchTypologyWithSons(this.tipologiaSlug).then(res => {
      this.listadoTipos = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id}));
    }).catch(e => {
      console.log(e);
      this.$refs.toast.showToast("Error obteniendo tipologías", null, "error", "5000");
    })
  },
  computed: {
    showModal() {
      return this.show;
    },
    personaContacto: {
      get: function () {
        return this.persona
      },
      set: function (newValue) {
        this.$emit("update:persona", newValue);
      }
    },
  },
  data() {
    return {
      listadoTipos: [],
      loading: false,
      tipologiaSlug: 'tipos-de-persona'
    }
  },
  methods: {
    crearPersona() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('crearPersona');
      })
    },

    guardarPersona() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        this.loading = true;
        this.$emit('guardarPersona')
      })
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    cerrarModal() {
      this.loading = false;
      this.personaContacto = {...PERSONAS_CONTACTO_FIELDS};
      this.$emit('cancelled',false);
    }
  }
}
</script>

<style>

</style>
